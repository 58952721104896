import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Form, Container,Row} from 'react-bootstrap'
import parse from "html-react-parser";

const StyleWrapper = styled.div`
.consentDesc p{
    color: #000 !important;
max-width: 100%;
text-align: justify;
font-size: 10px !important;
margin-left : 0 !important;
}
.container{
  display:flex;
  gap:20px
}
.consnt{
  margin-left:0px;
}
.consentSmall {
  cursor: pointer; 
  color: #0d6efd !important;
}
.consentSmall:hover {
  color: #00aae4 !important;
  cursor: pointer; 
}
.consentLink {
  cursor: pointer;
   
  padding: 8px 0px;
  font-size: 0.7rem;
}
.consentLink:hover {
  text-decoration: underline;
  color: ##00aae4;
}
.form-check-input[type='radio'] {
  border-radius: 0.25em;  
}
.form-check-input:checked[type='radio'] {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e');
  background-color:#198754!important;
}
.consentDesc p::before{
    display: none !important;
}
.checkboxConsent label{
    line-height: inherit !important;
text-align: left;
display: block;
margin-bottom: 5px;
margin-top: 3px;
font-size: 11px;
font-weight: 700;
margin-left: 20px;
color:#198754;
  }
  .checkboxConsent .form-check {
        padding-left: 0 !important;
        margin-top: 15px;
        border-color:green;
        
       
}
.errorMessage{
    color: #bf1650 !important;
    font-size: 14px !important;
}
.form-check{
    padding-left: 0 !important;
        margin-top: 15px !important;
}
.checkboxConsent .form-check-input{
    margin-left: 0 !important;  
}
@media(max-width:400px){
.checkboxConsent label{
  font-size:11px!important;
}
}
.form-check-input{
    margin-left : 0 !important;
    margin-top : 1px !important;
}
a {
    text-decoration: none;
}
@media(min-width:320px)and (max-width:540px){
  .contactconsent{
    text-align:left!important;
  }
  }
a:hover {
    text-decoration: underline;
}
@media(max-width:768px){
     .consentDesc p{
        color: #000 !important;
    width: auto;
    text-align: justify;
    font-size: 10px !important;
    }
`


export const Consent = (props) =>{

 const [formLoading, setFormLoading] = useState(false);
const [showClickToMoreOpt, setShowClickToMoreOpt] = useState(true);
// const [showConsenSection, setShowConsenSection] = useState(false);
const domainName = 'swmc.com';   
    const language = 'en';  
  
    const [consentData, setConsentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchConsentData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_LEAD_GENERATION_CONSENT_JSON);
          const data = await response.json();
      
          const selectedDomain = data[domainName] ? domainName : 'swmc.com';  
          const domainData = data[selectedDomain];   
 
          const consent = {
            submitButtonConsent: domainData.submit_button_consent[language],   
            automatedPhoneSolicitationConsent: domainData.automated_phone_solicitation_consent[language],   
            version: domainData.version,  
          };
      
          setConsentData(consent);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      
      useEffect(() => {
        fetchConsentData();
      }, []);

      useEffect(() => {
        if (consentData) {
          const consentElement = document.querySelector('.button-name');
          if (consentElement) {
            consentElement.innerText = 'clicking Submit';  
          }
        }
      }, [consentData]);
const showPhoneSoliticationConsent = () => {
  setShowClickToMoreOpt(false);
};
 
const hidePhoneSoliticationConsent = () => {
  setShowClickToMoreOpt(true);
};
    return(
        <StyleWrapper>
                    
                    <div className="consentDesc">
                    {!props.showConsenSection && (<div sm={4}>
                    <p className="mt-2 mb-1">
                        {/* <strong>Phone Solicitation Consent</strong> */}
                      </p>
                      {/* {consentData?.submitButtonConsent && (
                        <p className="m-0">
                      <p className="consent-font">
                        {parse(consentData.submitButtonConsent.content)}
                        </p>
                        </p>
                        )} */}
                      <p className="m-0">
                      {consentData?.submitButtonConsent && (
                      <p className="consent-font">
                        {parse(consentData.submitButtonConsent.content)}
                        </p>
                        )}
                      </p>
                       
                      </div>)}
                   
                      {props.showConsenSection && (
                         <div>
                     <Row style={{ marginTop:'1rem' }}>
                     
                     
                     <div className="contactconsent d-flex">
                        {showClickToMoreOpt ? (
                          <h6
                            className="consentSmall"
                            onClick={showPhoneSoliticationConsent}
                          >
                            {"Do you consent to Automated Phone Solicitation?"}{" "}
                            <span
                              id="showConsentText"
                              className="consentLink showConsentText"
                              title="Show Phone Solicitation Consent"
                            >
                              (click to show more)
                            </span>
                          </h6>
                        ) : (
                          <h6
                            className="consentSmall"
                            onClick={hidePhoneSoliticationConsent}
                          >
                            {"Do you consent to Automated Phone Solicitation?"}{" "}
                            <span
                              id="hideConsentText"
                              className="consentLink hideConsentText"
                              title="Hide Phone Solicitation Consent"
                            >
                              (click to show less)
                            </span>
                          </h6>
                        )}
                      </div>

                      {!showClickToMoreOpt ? (
                        <p
                          id="phoneSolicitationTerms"
                          className="consent-font pb-0"
                        >
                          {consentData?.automatedPhoneSolicitationConsent && (
                          <>
                          {parse(consentData.automatedPhoneSolicitationConsent.content)}
                          </>
                            )}

                          {/* By clicking I Consent below, you expressly consent to
                          Sun West Mortgage Company, Inc. making (or allowing to
                          be made on its behalf) a phone call, text message or
                          voicemail transmission to the phone number you entered
                          above for the purpose of soliciting your purchase of
                          consumer goods or services (including using an
                          automated system for the selection or dialing of phone
                          numbers, the playing of a recorded message when a
                          connection is made, or the transmission of a
                          pre-recorded voicemail), even if that phone number is
                          on a national, state, or internal Do Not Call
                          Registry. You are not required to click on I Consent
                          below (directly or indirectly) or agree to receive
                          such phone calls, text messages, or voicemail
                          transmissions as a condition of obtaining any goods or
                          services from Sun West Mortgage Company, Inc. or its
                          affiliates. */}
                        </p>
                      ) : (
                        ""
                      )}
                      
                    </Row>
                    <Container className='consnt p-0'>
                      
                      <div>
                      <Form.Check
                        label="I Consent"
                        // name="acceptConsent"                        
                        type="radio"
                     
                        className="checkboxConsent"
                        //onChange={props.handleChange}
                        onClick={props.handleClick1}
                        name="TCPA_Consent_Given"
                        id="iConsent"
                        // name={props.name} 
                        // ref={props.register}
                        {...props}
                        defaultChecked
                        
                      />
                     </div> 
              
                   
                     <div>  

                      <Form.Check
                        label="I DO NOT Consent"
                        // name="acceptConsent"                        
                        type="radio"
                        
                        className="checkboxConsent check3"
                        //onChange={props.handleChange}
                        onClick={props.handleClick2}
                        name="TCPA_Consent_Given"
                        id="iDoNotConsent"
                        // name={props.name} 
                        // ref={props.register}
                        {...props}
                      
                      />

                      </div>
                    
                      </Container> 
                      </div>)}
                      {/* {props.errors[props.name] && <span className="errorMessage">{props.errors[props.name].message}</span>} */}
                        </div>
{/*                 
                        {!props.showConsenSection && (<div sm={4}>
                    
                      <div className="divflex">
                        
                        <div>
                          <Button className="btn-submit" type="submit">
                            {formLoading ? (
                              <>
                                <Spinner
                                  animation="border"
                                  aria-hidden="true"
                                  as="span"
                                  role="status"
                                  size="md"
                                />{" "}
                                Please wait...
                              </>
                            ) : (
                              <>Submit</>
                            )}
                          </Button>
                           
                        </div>
                      </div>
                  </div>)} */}

                    </StyleWrapper>
    )
}
export default Consent