import React from "react";
import styles from "../../styles.module.css";
import styled from "styled-components";
import { Row, Container } from "react-bootstrap";
import Login from "../Login";

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ._38z3qfEzUjJKUXYzHTcqJc {
    justify-content: center;
    display: flex;
    margin-left: auto !important;
  }

  .loanStatusRect {
    background: rgba(255, 248, 248, 0.3);
    box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }

  @media (max-width: 475px) {
    .loanStatusRect {
      width: 300px;
    }
  }
`;

export const LoanStatus = () => {
  return (
    <Container fluid>
      <Row className={styles.loanStatusRow}>
        <h2>Existing Partners</h2>

        <LoginWrapper>
          <div className="loanStatusRect">
            <div>
              <h3>See My Loan Status</h3>

              <Login />
            </div>
          </div>
        </LoginWrapper>
      </Row>
    </Container>
  );
};

export default LoanStatus;
