import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './index.css'

const LoadingIndicator = () => (
  
  <div className='loader-container'>
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

export default LoadingIndicator;
