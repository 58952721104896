import React from "react";
import Form from "react-bootstrap/Form";
import Classes from "./index.css";

const Dropdown = (props) => {
  let options;
  let newProps = { ...props };
  delete newProps.register;
  delete newProps.Change;
  if (props.values.length > 0) {
    options = props.values.map((ele) => {
      if (typeof ele == "object") {
        if (ele.name === "Select a state") {
          return (
            <option value=" " key={ele.name}>
              {ele.name}
            </option>
          );
        }
        return (
          <option value={ele.name} key={ele.name}>
            {ele.name}
          </option>
        );
      } else {
        return (
          <option value={ele} key={ele}>
            {ele}
          </option>
        );
      }
    });
  }
  return (
    <div style={{ marginTop: "5px" }}>
      <Form.Control
        as="select"
        value={props.defaultValue}
        className={`${Classes.dropdown} form-select`}
        name={props.name}
        ref={props.register}
        placeholder={props.placeholder}
        onChange={(ele) => props.Change(ele)}
        style={{ boxShadow: "none", outline: "none" }}
        {...newProps}
      >
        {options}
      </Form.Control>
      {props.register
        ? props.errors[props.name] && <p>{props.errors[props.name].message}</p>
        : null}
    </div>
  );
};
export default Dropdown;
