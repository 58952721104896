import React from "react";
import styles from "../../styles.module.css";
import ProductCard from "../productCard";
import { Row, Col, Container } from "react-bootstrap";

export const ProductSection = () => {
  return (
    <div className={styles.productSection}>
      <Container>
        <img
          src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueBg.svg"
          className={styles.rightbluebg}
          alt=''
        />

        <img  alt=''
          src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueBg.svg"
          className={styles.leftbluebg}
        />

        <img alt=''
          src="https://resources.swmc.com/swmc-images/WholesaleLending/dot4.svg"
          className={styles.leftdot}
        />

        <img alt=''
          src="https://resources.swmc.com/swmc-images/WholesaleLending/curves.svg"
          className={styles.rightcurve}
        />

        <img alt=''
          src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueDot.svg"
          className={styles.bluesemidot}
        />
        <Row className={styles.productRow}>
          <h2>Partner With Sun West Wholesale</h2>
          <h3>Choose Your Product to View Rates and Submit Your Loan</h3>
        </Row>

        <div className={styles.cardDiv}>
          <Row className={styles.product_card_row}>
            {/* <Col md={3} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/nonQm.svg"
                text="Non-QM"
                link="https://nonqm.swmc.com"
              />
            </Col> */}
            <Col md={4} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/FHALoan.svg"
                text="FHA Loans"
                link="https://fha.swmc.com"
              />
            </Col>
            <Col md={4} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/USDAloan.svg"
                text="USDA-Loans"
                link="https://usda.swmc.com"
              />
            </Col>
            {/* </Row>

        <Row> */}
            <Col md={4} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/VALoan.svg"
                text="VA-Loans"
                link="https://va.swmc.com"
              />
            </Col>
            <Col md={4} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/ConventionalLoan.svg"
                text="Conventional Loans"
                link="https://conv.swmc.com"
              />
            </Col>
            {/* <Col md={3} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/dscr.svg"
                text="DSCR Program"
                link="https://dscr.swmc.com"
              />
            </Col> */}
            {/* <Col md={3} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/JumboLoan.svg"
                text="Jumbo Loans"
                link="https://jumbo.swmc.com"
              />
            </Col> */}
            {/* <Col md={3} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/DownPayment.svg"
                text="Foreign National"
                link="https://foreignnational.swmc.com"
              />
            </Col> */}
            {/* </Row>

        <Row> */}
           
            <Col md={4} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/ReverseMortgage.svg"
                text="Reverse Mortgages"
                link="https://hecm.swmc.com"
              />
            </Col>
            <Col md={4} sm={6} xs={6}>
              <ProductCard
                src="https://resources.swmc.com/swmc-images/WholesaleLending/RenovationLoan.svg"
                text="Renovation Loans"
                link="https://203k.swmc.com"
              />
            </Col>
          </Row>
        </div>

        <img alt=''
          src="https://resources.swmc.com/swmc-images/WholesaleLending/eSquareMobile.svg"
          className={styles.eSquare}
        />
        <img alt=''
          src="https://resources.swmc.com/swmc-images/WholesaleLending/ellipseMobile.svg"
          className={styles.ellipseMobile}
        />
      </Container>
    </div>
  );
};

export default ProductSection;
