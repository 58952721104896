export const removeSpaces = (value) =>
  typeof value === "string" ? value.trim() : value;

export function removeNonAsciiSpecialCharacters(str) {
  //check if pass value is null or blank.
  //return empty string if pass value is null or blank
  if (str === null || str === "") {
    return "";
  } else {
    //convert the pass value to string
    str = str.toString();
    //remove non-printable ASCII chars and replace it with empty string
    str = str.replace(/[^\x20-\x7E]/g, "");
    //replace all special characters with empty string
    str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}0-9[\]\\/]/g, "");
    //return updated string to calling function
    return str;
  }
}
